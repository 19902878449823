import {
    fetchDataRequest,
    fetchDataSuccess,
    fetchOrderDetailsSuccess,
    fetchDataError,
    fetchUserDetailsSuccess, setLoadingFlag
} from ".";
import {API} from "../../utils/API";
import {errorHandler} from "../../utils/utils";
import {ACTIONS_NAME} from "../constants";
import dayjs from "dayjs";

export const fetchUser = () => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.USER_BASE_URL)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.USER));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchInvoiceList = () => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.INVOICE_BASE_URL)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.INVOICES));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};
export const fetchRoles = () => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.ROLES_BASE_URL)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.ROLES));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchCompanies = () => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.COMPANIES_BASE_URL)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.COMPANIES));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchOrderDetails = (orderId, index) => {
    return (dispatch) => {
        dispatch(setLoadingFlag(true))
        dispatch(fetchDataRequest());
        return API.get(`${API.ORDERS_BASE_URL}/${orderId}`)
            .then((response) => {
                dispatch(setLoadingFlag(false))
                return dispatch(fetchOrderDetailsSuccess(response?.data, index));
            })
            .catch((error) => {
                dispatch(setLoadingFlag(false))
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchUserDetails = (userId) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(`${API.USER_BASE_URL}/${userId}`)
            .then((response) => {
                return dispatch(fetchUserDetailsSuccess(response?.data));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchInvoiceDetails = (invoiceId) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(`${API.INVOICE_BASE_URL}/${invoiceId}`)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.INVOICE_DETAILS));
            })
            .catch((error) => {
                dispatch(fetchDataError(error.message));
            });
    };
};

export const fetchMaintenance = (navigate, signIn, signOut) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.MAINTENANCE)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.MAINTENANCE));
            })
            .catch((error) => {
                errorHandler(error, navigate, dispatch, signIn, signOut, "maintenance");
            });
    };
};

export const fetchOrdersWaitingForApproval = (navigate, signIn, signOut) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(`${API.ORDERS_BASE_URL}?status=WAITING_FOR_APPROVAL`)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.ORDERS_WAITING_FOR_APPROVAL));
            })
            .catch((error) => {
                errorHandler(error, navigate, dispatch, signIn, signOut, "approval");
            });
    };
};

export const fetchPriceForecast = (navigate, signIn, signOut) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(API.FORECAST)
            .then((response) => {
                const newData = response.data.map((obj, index) => ({
                    ...obj,
                    id: index
                }));
                const sortedData = newData?.sort((a, b) => {
                    return dayjs(a.date).diff(dayjs(b.date));
                });
                return dispatch(fetchDataSuccess(sortedData, ACTIONS_NAME.FORECAST));
            })
            .catch((error) => {
                errorHandler(error, navigate, dispatch, signIn, signOut, "forecast");
            });
    };
};