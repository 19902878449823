import {createReducer, updateObject} from "./reducerUtilities";

export const initialState = {
    maintenanceDetails: {
        start: null,
        end: null,
        availability: '',
        note: '',
        maintenanceId: ''
    },
}
export const maintenanceReducer = createReducer(initialState, {
    MAINTENANCE_DETAILS: maintenanceDetails,
    CLEAR_MAINTENANCE_DETAILS: clearMaintenanceDetails
})

function maintenanceDetails(state, action) {
    return {
        ...state,
        maintenanceDetails: {
            ...state.maintenanceDetails,
            ...action.payload,
            maintenanceId: action.payload.id
        },
    };
}

function clearMaintenanceDetails(state, action) {
    return updateObject(state, {
        maintenanceDetails: {
            start: "",
            end: "",
            availability: "",
            note: "",
            maintenanceId: ""
        }
    });
}