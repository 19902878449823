export const COLUMN_NAME = {
  ORDER_NUMBER: "Order No.",
  ORDER_DATE: "Order Date",
  START_TIME: "Start Time",
  END_TIME:" End Time",
  DURATION: "Duration",
  AMOUNT: "Amount",
  TRAILER_ID: "Trailer Id",
  STATUS: "Status",
  ORDER_TYPE: "Order Type",
  ORDERED_BY: "Ordered by",
  COMPANY: "Company"
};

export const STATUS = {
  ORDERED: "ORDERED",
  WAITING_FOR_APPROVAL :"WAITING FOR APPROVAL",
  REJECTED: "REJECTED",
  APPROVED:"APPROVED",
  DELIVERED: "DELIVERED",
  COST_ADDED:"COST ADDED",
  CANCELLED: "CANCELLED",
  CREATED: "CREATED",
  INVOICED: "INVOICED",
  ON_HOLD	:"ON HOLD",
  PLANNED_LOCKED: "PLANNED & LOCKED",
  IN_PRODUCTION: "IN PRODUCTION",
  INVOICE_CREATED:"INVOICE CREATED",
  ERROR:"ERROR"
};

export const id = "id";
export const endOffTakeTime = "endOffTakeTime";
export const startOffTakeTime = "startOffTakeTime";
export const orderNumber = 'orderNumber';
export const duration = "duration";
export const amount = "amount";
export const trailerId = "trailerId";
export const status = "status";
export const demandtype = "demandtype";
export const Company = "Company";
export const orderedby = "orderedby";
export const actions = "actions";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_FORMAT = "HH:mm";

export const BTN_CANCEL = "Cancel";
export const BTN_DELETE = "Delete";

export const ORDER_GRAPH = {
  ORDER_OVERLAPPED: "Orders Overlapped",
  DATE: "Date:",
  AMOUNT: "Total Amount:"
};
