import {createReducer} from "./reducerUtilities";

export const initialState = {
    status: null
}
export const approvalReducer = createReducer(initialState, {
    SET_APPROVAL_STATUS: setApprovalStatus,
    CLEAR_APPROVAL_STATUS: clearApprovalStatus,
})

function setApprovalStatus(state, action) {
    return {
        ...state,
        status: action.payload,
    };
}

function clearApprovalStatus(state) {
    return {
        ...state,
        status: null,
    };
}