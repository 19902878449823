import {dateStringToUTCObject} from "../../utils/date-utils";
import {createReducer, updateObject} from "./reducerUtilities";

export const initialState = {
    orderDetails: {
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
        totalAmount: "",
        heading: "",
        warningDemand: false,
        unit: ""
    },
    flexibleDemand: [],
    flexibleDemandErrors: {
        isSameDate: false,
        isStartDateGreater: false,
        isSameDateAndTime: false,
        isAmtLess: false,
        isAmtGreater: false
    },
    editOrderId: "",
    editFlexibleDemand: false,
    rowOrderDetails: {
        orderSummary: [],
        supplyLines: []
    }
};

export const orderReducer = createReducer(initialState, {
    FETCH_ORDER_DETAILS_SUCCESS: fetchOrderDetailsSuccess,
    ORDER_DETAILS: setOrderDetails,
    CLEAR_ORDER_DETAILS: clearOrderDetails,
    CLEAR_ROW_ORDER_DETAILS: clearOrderDetailsRow,
    SET_FLEXIBLE_DEMAND: setFlexibleDemand,
    SET_FLEXIBLE_DEMAND_FLAG: setFlexibleDemandFlag,
    DELETE_ORDER_FLEXIBLE_DEMAND: deleteFlexibleOrder,
    UPDATE_ORDER_FLEXIBLE_DEMAND: updateOrderFlexibleDemand,
    CLEAR_FLEXIBLE_DEMAND: clearFlexibleDemand,
    SET_FLEXIBLE_DEMAND_ERRORS: setFlexibleDemandErrors,
    GET_EDIT_ORDER_DETAILS: getEditOrderDetails,
    GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS: getFlexibleDemandEditOrderDetails,
    REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND: removeFirstItemFlexibleEditDemand,
    CLEAR_EDIT_FLEXIBLE_DEMAND: clearOrderDetails
})


function setFlexibleDemandFlag(state, action) {
    return updateObject(state, {
        editFlexibleDemand: action.payload
    });
}

function fetchOrderDetailsSuccess(state, action) {
    const sortedArray = action.item?.lines.sort((a, b) => {
        return (
            dateStringToUTCObject(a.startDateTime) -
            dateStringToUTCObject(b.startDateTime)
        );
    });
    return updateObject(state, {
        rowOrderDetails: {
            orderSummary: [
                {
                    index: action.index,
                    orderNumber: action.item?.orderNumber,
                    createdAt: action.item?.createdAt,
                    createdBy: action.item?.createdBy,
                    demandType: action.item?.demandType,
                    endOffTakeTime: action.item?.endOffTakeTime,
                    hydrogenDemand: action.item?.hydrogenDemand,
                    id: action.item?.id,
                    lines: action.item?.lines,
                    company: action.item?.company,
                    maxPrice: action.item?.maxPrice,
                    purchaseOrderNumber: action.item?.purchaseOrderNumber,
                    startOffTakeTime: action.item?.startOffTakeTime,
                    status: action.item?.status,
                    totalMassDelivered: action.item?.totalMassDelivered,
                    totalP1HydrogenMass: action.item?.totalP1HydrogenMass,
                    totalP1HydrogenPrice: action.item?.totalP1HydrogenPrice,
                    totalP2HydrogenMass: action.item?.totalP2HydrogenMass,
                    totalP2HydrogenPrice: action.item?.totalP2HydrogenPrice,
                    trailerId: action.item?.trailerId,
                    updatedBy: action.item?.updatedBy
                }
            ],
            supplyLines: sortedArray.map((itm, index) => {
                return {...itm, index: index + 1};
            })
        }
    });
}


function setOrderDetails(state, action) {
    return updateObject(state, {
        orderDetails: {
            ...action.payload
        }
    });
}

function clearOrderDetails(state, action) {
    return updateObject(state, {
        orderDetails: {
            startDate: "",
            startHour: "",
            endDate: "",
            endHour: "",
            totalAmount: "",
            heading: "",
            warningDemand: false,
            unit: ""
        },
        editOrderId: ""
    });
}

function clearOrderDetailsRow(state, action) {
    return updateObject(state, {
        rowOrderDetails: {
            orderSummary: [],
            supplyLines: []
        },
        editOrderId: ""
    });
}


function setFlexibleDemand(state, action) {
    return updateObject(state, {
        flexibleDemand: [...state.flexibleDemand, action.payload]
    });
}

function deleteFlexibleOrder(state, action) {
    return updateObject(state, {
        flexibleDemand: [
            ...state.flexibleDemand.filter((itm, i) => i !== state.currentIndex)
        ]
    });
}

function updateOrderFlexibleDemand(state, action) {
    const updatedFlexibleDemand = [
        ...state.flexibleDemand.slice(0, state.currentIndex),
        action.payload,
        ...state.flexibleDemand.slice(state.currentIndex + 1)
    ];

    return updateObject(state, {
        flexibleDemand: updatedFlexibleDemand
    });
}

function clearFlexibleDemand(state, action) {
    return updateObject(state, {
        flexibleDemand: [],
        networkErrorMessage: ""
    });
}

function setFlexibleDemandErrors(state, action) {
    return updateObject(state, {
        flexibleDemandErrors: {
            ...state.flexibleDemandErrors,
            ...action.payload
        }
    });
}

function getEditOrderDetails(state, action) {
    return updateObject(state, {
        orderDetails: {
            ...state.orderDetails,
            ...action.payload
        },
        editOrderId: action.payload.orderId
    });
}

function getFlexibleDemandEditOrderDetails(state, action) {
    return {
        ...state,
        orderDetails: {
            ...state.orderDetails,
            ...action.payload,
            id: action.payload.id
        },
        editOrderId: action.payload.orderId
    };
}

function removeFirstItemFlexibleEditDemand(state, action) {
    return {
        ...state,
        flexibleDemand: [state.flexibleDemand.pop()]
    };
}
