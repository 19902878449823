import {createReducer, updateObject} from "./reducerUtilities";

export const initialState = {
    loading: false,
    error: null,
    item: [],
    selectedUserDetails: {},
    staticCost: [],
    types: [],
    user: [],
    roles: [],
    companies: [],
    invoiceDetails: {},
    maintenance: {},
    invoices: [],
    forecastData: [],
    ordersWaitingForApproval: [],
    networkErrorMessage: "",
    editOrderId: "",
    notifications: [],
    notificationObject: {},
    settings: {
        data: []
    },
    report: {},
    currentIndex: -1,
    inputEmptyErrors: {
        isIdEmpty: false,
        isCapacityEmpty: false,
        isStartDateEmpty: false,
        isEndDateEmpty: false,
        isStartHourEmpty: false,
        isEndHourEmpty: false
    },
}

export const baseReducer = createReducer(initialState, {
    FETCH_DATA_REQUEST: fetchDateRequest,
    FETCH_DATA_SUCCESS: fetchDataSuccess,
    FETCH_DATA_ERROR: fetchDataError,
    CLEAR_INVOICE_DETAILS: clearInvoiceDetails,
    SET_SETTING_DETAILS: setSettingDetails,
    SET_NETWORK_ERROR_MESSAGE: setNetworkErrorMessage,
    CLEAR_REPORT: clearReport,
    CURRENT_INDEX: setCurrentIndex,
    IS_INPUT_EMPTY: inputEmpty,
    CLEAR_EMPTY_INPUT_ERRORS: clearEmptyInputError
})

function setCurrentIndex(state, action) {
    return updateObject(state, {
        currentIndex: action.payload
    });
}

function inputEmpty(state, action) {
    return {
        ...state,
        inputEmptyErrors: {
            ...state.inputEmptyErrors,
            ...action.payload
        }
    };
}

function clearReport(state) {
    return updateObject(state, {
        report: {}
    });
}

function clearEmptyInputError(state) {
    return updateObject(state, {
        inputEmptyErrors: {
            isIdEmpty: false,
            isCapacityEmpty: false,
            isStartDateEmpty: false,
            isEndDateEmpty: false,
            isStartHourEmpty: false,
            isEndHourEmpty: false
        }
    });
}

function setSettingDetails(state, action) {
    return updateObject(state, {
        settings: {
            data: action.payload
        }
    });
}

function setNetworkErrorMessage(state, action) {
    return updateObject(state, {
        networkErrorMessage: action.payload
    });
}

function fetchDateRequest(state) {
    return updateObject(state, {
        loading: true,
        error: null
    });
}

function fetchDataSuccess(state, action) {
    return updateObject(state, {
        loading: false,
        [action.key]: action.item
    });
}

function fetchDataError(state, action) {
    return updateObject(state, {
        loading: false,
        error: action.payload.error,
        item: []
    });
}

function clearInvoiceDetails(state) {
    return updateObject(state, {
        invoiceDetails: {},
        networkErrorMessage: "",
        editOrderId: ""
    });
}
