import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./UserAccess.module.css";
import {
  BUTTON_NAME,
  PERMISSION_ERROR_HEADER,
  PERMISSION_ERROR_MESSAGE,
} from "../../store/constants";
import { HOME_ROUTE } from "../../utils/RoutesConstants";
import PropTypes from 'prop-types';
const UserAccess = ({ children, userPermission }) => {
  const navigate = useNavigate();

  const userRolePermission = useSelector(
    (state) => state.userReducer.userDetails?.permissions
  );
  
  const handleClick = () => {
    navigate(HOME_ROUTE);
  };

  return (
      userRolePermission?.length > 0 && !userRolePermission?.includes(userPermission) ? (
        <div className={styles.userAccess}>
          <div className={styles.userAccess__container}>
              <h1 className={styles.userAccess__heading}>
                {PERMISSION_ERROR_HEADER}
              </h1>
              <h3 className={styles.userAccess__subheading}>
                {PERMISSION_ERROR_MESSAGE}
              </h3>
              <button
                className={styles.userAccess__login}
                onClick={handleClick}
                data-testid="toBack"
              >
                {BUTTON_NAME}
              </button>
          </div>
        </div>
      ) : (
        <>{children}</>
      )
  );
};
UserAccess.propTypes = {
  children:PropTypes.any,
  userPermission:PropTypes.any
};
export default UserAccess;
