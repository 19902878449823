import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { Outlet } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import style from "./LandingPage.module.css";
import logo from "../../assets/Icons/logo.svg";
import Header from "../../components/header/Header";
import { clearOrderDetails } from "../../store/actions";
import { LinkWithConfirm } from "../../context/confirmContext";
import LeftMenu from "../LeftMenu/LeftMenu";
import { HOME_ROUTE } from "../../utils/RoutesConstants";
import { DEFAULT_COMPANY_NAME } from "../../store/constants";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { TRANS_KEYS } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { Backdrop, CircularProgress } from "@mui/material";
const drawerWidth = 400;

const openedMixin = (theme) => ({
  width: drawerWidth,
  height:'100%',
  background: "transparent",
  boxShadow: "40px 0 50px rgba(0,0,0,0.4)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  background: "transparent",
  boxShadow: "40px 0 50px rgba(0,0,0,0.2)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: 0
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const LandingPage = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const {t} = useTranslation(TRANS_KEYS.translation, {keyPrefix: TRANS_KEYS.LEFT_MENU});


  const company = useSelector(
    (state) => state.userReducer.userDetails?.companies
  );
  const firstName = useSelector(
    (state) => state?.userReducer?.userDetails?.firstName
  );
  const {loadingFlag} = useSelector(
    (state) => state?.loadingReducer
  );
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
      if (browserZoomLevel > 250) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Backdrop open={loadingFlag}><CircularProgress/></Backdrop>
      <Drawer variant="permanent" open={open}>
        <div>
          <DrawerHeader>
            <LinkWithConfirm href={HOME_ROUTE} action={clearOrderDetails}>
              <div className={style.headerLogo} data-testid="logo">
                <img src={logo} alt="logo" />
              </div>
              {firstName && (
                <div className={style.profileSlider}>
                  {t(`GREET`)} <br />
                  {capitalizeFirstLetter(firstName)} <br />
                  <div className={style.companyName}>
                    {company?.length > 1 ? DEFAULT_COMPANY_NAME : company[0]?.name}
                  </div>
                </div>
              )}
            </LinkWithConfirm>
            <div className={style.burgerButton}>
              <IconButton
                onClick={handleDrawerClose}
                data-testid="btnChevron"
                className={open ? style.chevronBtn : null}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon data-testid="btnChevronRight" />
                ) : (
                  <ChevronLeftIcon data-testid="btnChevronLeft" />
                )}
              </IconButton>
            </div>
          </DrawerHeader>
        </div>
        {firstName && (
          <div className={style.sliderContainer}>
            <LeftMenu />
          </div>
        )}
         {firstName && ( <div className={style.footer}>
          <div>
            <span className={style.footerEmail}><LocalPhoneIcon className={style.icon}/></span>
            <span className={style.footerPhoneContent}>{t(`PHONE`)}</span>
          </div>
          <div>
            <span className={style.footerEmail}><MailOutlineIcon className={style.icon}/> </span>
            <span className={style.footerEmailContent}>{t(`EMAIL`)}</span>
          </div>
          </div>)}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "transparent",
          p: "0px 24px 0 24px",
          overflow: "hidden",
          marginTop: "-1vh",
          zIndex: 1
        }}
      >
        <Header open={open} handleDrawerOpen={handleDrawerOpen} />
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};
export default LandingPage;
