import {
  ORDER_DETAILS,
  CLEAR_ORDER_DETAILS,
  GET_USER_DETAILS,
  SET_NETWORK_ERROR_MESSAGE,
  SET_FLEXIBLE_DEMAND,
  CLEAR_FLEXIBLE_DEMAND,
  SET_FLEXIBLE_DEMAND_ERRORS,
  GET_EDIT_ORDER_DETAILS,
  GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS,
  CLEAR_EDIT_FLEXIBLE_DEMAND,
  REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND,
  IS_INPUT_EMPTY,
  CLEAR_EMPTY_INPUT_ERRORS,
  SET_SETTING_DETAILS,
  GET_STATIC_COST_DETAILS,
  CLEAR_USER_LIST_DETAILS,
  GET_USER_LIST_DETAILS,
  CLEAR_STATIC_COST_DETAILS,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  DELETE_ORDER_FLEXIBLE_DEMAND,
  UPDATE_ORDER_FLEXIBLE_DEMAND,
  SET_FLEXIBLE_DEMAND_FLAG,
  CURRENT_INDEX,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_INVOICE_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_SUCCESS,
  CLEAR_ROW_ORDER_DETAILS,
  CLEAR_INVOICE_DETAILS,
  GET_COMPANIES_LIST_DETAILS,
  CLEAR_COMPANY_LIST_DETAILS,
  MAINTENANCE_DETAILS,
  CLEAR_MAINTENANCE_DETAILS,
  SET_LOADING,
  SET_APPROVAL_STATUS,
  ACTIONS_NAME, CLEAR_REPORT
} from "../constants";

export const setOrderDetails = (payload) => (dispatch) => {
  dispatch({
    type: ORDER_DETAILS,
    payload
  });
};
export const clearOrderDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_DETAILS
  });
};
export const clearRowOrderDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_ROW_ORDER_DETAILS
  });
};

export const clearReport = () => (dispatch) => {
  dispatch({
    type: CLEAR_REPORT
  });
};
export const clearInvoiceDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_INVOICE_DETAILS
  });
};

export const clearStaticCostDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATIC_COST_DETAILS
  });
};
export const getuserDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_USER_DETAILS,
    payload
  });
};

export const setSettingDetails = (payload) => (dispatch) => {
  dispatch({
    type: SET_SETTING_DETAILS,
    payload
  });
};

export const setNetworkErrorMessage = (payload) => (dispatch) => {
  dispatch({
    type: SET_NETWORK_ERROR_MESSAGE,
    payload
  });
};
export const setFlexibleDemand = (payload) => (dispatch) => {
  dispatch({
    type: SET_FLEXIBLE_DEMAND,
    payload
  });
};

export const clearFlexibleDemand = () => (dispatch) => {
  dispatch({
    type: CLEAR_FLEXIBLE_DEMAND
  });
};

export const setFlexibleDemandErrors = (payload) => (dispatch) => {
  dispatch({
    type: SET_FLEXIBLE_DEMAND_ERRORS,
    payload
  });
};

export const getEditOrderDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_EDIT_ORDER_DETAILS,
    payload
  });
};

export const getStaticCostDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_STATIC_COST_DETAILS,
    payload
  });
};
export const getUserListDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_USER_LIST_DETAILS,
    payload
  });
};

export const maintenanceDetails = (payload) => (dispatch) => {
  dispatch({
    type: MAINTENANCE_DETAILS,
    payload
  });
};
export const getCompaniesListDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_COMPANIES_LIST_DETAILS,
    payload
  });
};

export const clearUserListDetails = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_USER_LIST_DETAILS,
    payload,
  });
};

export const clearMaintenanceDetails = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_MAINTENANCE_DETAILS,
    payload,
  });
};

export const clearCompanyListDetails = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY_LIST_DETAILS,
    payload,
  });
};

export const getFlexibleEditOrderDetails = (payload) => (dispatch) => {
  dispatch({
    type: GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS,
    payload
  });
};

export const clearEditFlexibleDemand = () => (dispatch) => {
  dispatch({
    type: CLEAR_EDIT_FLEXIBLE_DEMAND
  });
};
export const deleteOrderFromFlexibleDemand = (payload) => (dispatch) => {
  dispatch({
    type: DELETE_ORDER_FLEXIBLE_DEMAND,
    payload
  });
};
export const updateOrderFromFlexibleDemand = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ORDER_FLEXIBLE_DEMAND,
    payload
  });
};
export const setFlexibleDemandFlag = (payload) => (dispatch) => {
  dispatch({
    type: SET_FLEXIBLE_DEMAND_FLAG,
    payload
  });
};
export const clickedIndex = (payload) => (dispatch) => {
  dispatch({
    type: CURRENT_INDEX,
    payload
  });
};
export const removeFirstItemFromFlexibleEditDemand = () => (dispatch) => {
  dispatch({
    type: REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND
  });
};

export const checkIsInputEmpty = (payload) => (dispatch) => {
  dispatch({
    type: IS_INPUT_EMPTY,
    payload
  });
};

export const clearEmptyInputFieldErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_EMPTY_INPUT_ERRORS
  });
};
export const fetchDataRequest = () => {
  return {
    type: FETCH_DATA_REQUEST
  };
};

export const fetchDataSuccess = (item, key) => {
  return {
    type: FETCH_DATA_SUCCESS,
    item,
    key
  };
};

export const fetchDataError = (error) => {
  return {
    type: FETCH_DATA_ERROR,
    payload: { error }
  };
};

export const fetchOrderDetailsSuccess = (item, index) => {
  return {
    type: FETCH_ORDER_DETAILS_SUCCESS,
    item,
    index
  };
};

export const fetchUserDetailsSuccess = (item) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    item
  };
};

export const fetchInvoiceDetailsSuccess = (item) => {
  return {
    type: FETCH_INVOICE_DETAILS_SUCCESS,
    item
  };
};

export const setLoadingFlag = (payload) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload
  });
};

export const setApprovalStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_APPROVAL_STATUS,
    payload
  });
};

export const clearNotificationObject = () => (dispatch) => {
  dispatch(fetchDataSuccess(null,ACTIONS_NAME.NOTIFICATION_OBJECT));
};
