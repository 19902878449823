import {createReducer, updateObject} from "./reducerUtilities";

export const initialState = {
    staticCostDetails: {
        startDateTime: "",
        endDateTime: "",
        amount: "",
        type: "",
        costId: ""
    },
}

export const staticCostReducer = createReducer(initialState, {
    GET_STATIC_COST_DETAILS: getStaticCostDetails,
    CLEAR_STATIC_COST_DETAILS: clearStaticCostDetails,
})

function getStaticCostDetails(state, action) {
    return updateObject(state, {
        staticCostDetails: {
            ...state.staticCostDetails,
            ...action.payload
        }
    });
}

function clearStaticCostDetails(state, action) {
    return updateObject(state, {
        staticCostDetails: {
            startDateTime: "",
            endDateTime: "",
            amount: "",
            type: "",
            costId: ""
        },
    });
}