import {
    legacy_createStore as createStore,
    combineReducers,
    applyMiddleware,
} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {baseReducer} from "./reducers/baseReducer";
import {orderReducer} from "./reducers/orderReducer";
import {staticCostReducer} from "./reducers/staticCostReducer";
import {userReducer} from "./reducers/userReducer";
import {maintenanceReducer} from "./reducers/maintenanceReducer";
import {loadingReducer} from "./reducers/loadingReducer";
import {approvalReducer} from "./reducers/approvalReducer";

const appReducer = combineReducers({
    baseReducer: baseReducer,
    orderReducer: orderReducer,
    staticCostReducer: staticCostReducer,
    userReducer: userReducer,
    maintenanceReducer: maintenanceReducer,
    loadingReducer: loadingReducer,
    approvalReducer: approvalReducer

})
const middleware = [thunk];
export const store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

