import React from "react";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./InboxColumns.jsx";
import crossIcon from "../../assets/Icons/close.svg";
import HeaderTitle from "../page-Header/HeaderTitle";
import { TRANS_KEYS } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import commonStyle from '../../common/Common.module.css';
import CustomPagination from "../../components/custom-pagination/CustomPagination.jsx";
import styles from './Inbox.module.css';
import Loading from "../../components/loading/Loading";

const Inbox = ({handleNotificationClick, showInbox, setShowInbox }) => {

    const { t } = useTranslation(TRANS_KEYS.translation, {
        keyPrefix: TRANS_KEYS.NOTIFICATION
    });
    const {
        notifications,
        loading
    } = useSelector((state) => state?.baseReducer);

    const handleClose = () => {
        setShowInbox(!showInbox);
    };
    return (
        <Modal
            onClose={handleClose}
            open={showInbox}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={commonStyle.modalPosition}>
                <Box className={styles.modalContainer}>
                    <HeaderTitle withButton={false} title={t(`INBOX`)} />
                    <Box onClick={handleClose}>
                        <img
                            src={crossIcon}
                            alt="crossIconOfInbox"
                            className={commonStyle.cursorPosition}
                        />
                    </Box>
                </Box>
                <Box sx={{width: "100%",}}>
                    <DataGrid
                        rows={notifications ?? []}
                        columns={columns}
                        loading={loading}
                        onRowClick={(item) => handleNotificationClick(item?.row)}
                        disableColumnSelector
                        disableVirtualization
                        slots={{
                            loadingOverlay: Loading,
                            pagination: CustomPagination,
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 25 } }
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

Inbox.propTypes = {
    handleNotificationClick: PropTypes.func,
    showInbox: PropTypes.bool,
    setShowInbox: PropTypes.func,
};

export default Inbox;
