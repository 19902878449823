import {createReducer} from "./reducerUtilities";

export const initialState = {
    loadingFlag: false
}
export const loadingReducer = createReducer(initialState, {
    SET_LOADING: setLoading,
    SET_LOADING_FALSE: cancelLoading,
})

function setLoading(state, action) {
    return {
        ...state,
        loadingFlag: action.payload,
    };
}

function cancelLoading(state, action) {
    return {
        ...state,
        loadingFlag: false,
    };
}