import {createTheme} from "@mui/material/styles";
import {materialUiColor} from "../../utils/utils";

const theme = createTheme({
    components: {
        MuiBadge: {
            styleOverrides: {
                root: {
                    margin: "10px 25px 0 0",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    borderRadius: '4px',
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid #878787 !important',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#878787',
                    transformOrigin: '0 0 !important',
                    '&.Mui-error': {
                        color: '#878787 !important'
                    },
                    '&.Mui-focused': {
                        color: '#878787 !important',

                    },
                    '&.MuiFormLabel-filled': {
                        color: '#878787 !important',
                    }
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#d32f2f',
                    '&.Mui-error': {
                        color: '#d32f2f'
                    }
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'var(--gray)',
                    width: 760,
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    zIndex: 99
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'var(--blackColor)'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: 'var(--blackColor) !important'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: 'var(--midBlue)',
                        color: 'var(--white)'
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    '&.Mui-error': {
                        color: '#878787'
                    },
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    height: 600,
                    border: "none",
                    "& .super-app-theme--header": {
                        backgroundColor: "rgba(217, 221, 223, 0.5);",
                    },
                    "& .super-app-theme--header:first-of-type": {
                        paddingLeft: "45px",
                    },
                    "& .MuiDataGrid-iconSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        border: "none",
                        margin: "0px",
                        marginTop: "20px",
                    },
                    "& .MuiDataGrid-selectedRowCount": {
                        display: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        maxHeight: "72px !important",
                        minHeight: "72px !important",
                        borderBottom: "1px solid var(--lightGray) !important",
                    },
                    "& .MuiDataGrid-row": {
                        maxHeight: "72px !important",
                        minHeight: "72px !important",
                        borderBottom: "1px solid var(--lightGray) !important",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontWeight: 700,
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1.1,
                        color: 'var(--grayMedium)'
                    },
                    "& .super-app-theme--COMPLETED": {
                        backgroundColor: 'var(--lightGray)',
                        "&:hover": {
                            backgroundColor: 'var(--lightGray)',
                        },
                        "&.Mui-selected": {
                            backgroundColor: 'var(--lightGray)',
                            "&:hover": {
                                backgroundColor: 'var(--lightGray)',
                            },
                        },
                    },
                    "& .super-app-theme--IN_PROGRESS": {
                        backgroundColor: 'var(--lightBlue)',
                        "&:hover": {
                            backgroundColor: 'var(--lightBlue)',
                        },
                        "&.Mui-selected": {
                            backgroundColor: 'var(--lightBlue)',
                            "&:hover": {
                                backgroundColor: 'var(--lightBlue)',
                            },
                        },
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    "& .Mui-selected": {
                        backgroundColor: "#E2EDF8 !important",
                        color: "#1E3A4F !important",
                        width: "56px",
                        height: "56px",
                        borderRadius: "8px",
                        padding: "16px 12px 16px 12px",
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: '"Source Sans Pro", sans-serif !important',
        fontSize: 16,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        h1: {
            fontSize: '3rem',
            fontWeight: 900,
            color: 'var(--yellowColor)',
            width: '100%'
        },
        h2: {
            fontSize: '1.4rem',
            fontWeight: 600,
        }
    },
    palette: {
        mode: "light",
        customColor: {
            ...materialUiColor,
        },
        primary: {
            main: '#1976d2',
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#E0C2FF',
            light: '#F5EBFF',
            contrastText: '#47008F',
        }
    },
});

export default theme;
