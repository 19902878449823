import {getUserPermissions} from "../../utils/utils";
import {createReducer, updateObject} from "./reducerUtilities";

export const initialState = {
    userDetails: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        roles: [],
        permissions: [],
        companies: []
    },
    userListDetails: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        roleIds: [],
        companyIds: [],
        userId: ""
    },
    companiesListDetails: {
        name: "",
        contactName: "",
        contactEmail: "",
        contactPhoneNumber: "",
        customerNumber: "",
        allowedOrderTypes: [],
        companyId: ""
    },
}

export const userReducer = createReducer(initialState, {
    GET_USER_DETAILS: getUserDetails,
    GET_USER_LIST_DETAILS: getUserListDetails,
    GET_COMPANIES_LIST_DETAILS: getCompanyListDetails,
    CLEAR_USER_LIST_DETAILS: clearUserListDetails,
    CLEAR_COMPANY_LIST_DETAILS: clearCompanyListDetails
});

function getUserDetails(state,action) {
    const permissions = getUserPermissions(action.payload.roles);
    return updateObject(state, {
        userDetails: {
            firstName: action.payload.firstName || "",
            lastName: action.payload.lastName || "",
            emailAddress: action.payload.emailAddress || "",
            phoneNumber: action.payload.phoneNumber || "",
            roles: action.payload.roles || [],
            permissions: permissions || [],
            companies: action.payload.companies || [],
        }
    });
}

function getUserListDetails(state, action) {
    return updateObject(state, {
        userListDetails: {
            ...state.userListDetails,
            ...action.payload
        },
    });
}

function getCompanyListDetails(state, action) {
    return {
        ...state,
        companiesListDetails: {
            ...state.companiesListDetails,
            ...action.payload
        }
    };
}

function clearUserListDetails(state, action) {
    return updateObject(state, {
        userListDetails: {
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            companyIds: [],
            roleIds: [],
            userId: ""
        }
    });
}

function clearCompanyListDetails(state, action) {
    return updateObject(state, {
        companiesListDetails: {
            name: "",
            contactName: "",
            contactEmail: "",
            contactPhoneNumber: "",
            customerNumber: "",
            allowedOrderTypes: [],
            companyId: ""
        }
    });
}